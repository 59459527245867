import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NbMomentDateModule, NbMomentDateService } from "@nebular/moment";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";
import {
  NbAutocompleteModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbNativeDateService,
  NbSidebarModule,
  NbTimepickerModule,
  NbToastrModule,
} from "@nebular/theme";
import { NgxEchartsModule } from "ngx-echarts";
import { NgxMaskModule } from "ngx-mask";
import { environment } from "../environments/environment";
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ptPt } from "./locales/pt-PT";
import { HttpAsyncModule } from "./modules/http-async/http-async.module";
import { BaseUrlInterceptor } from "./modules/http-async/interceptors/base-url.interceptor";
import { BearerTokenInterceptor } from "./modules/http-async/interceptors/bearer-token.interceptor";
import { HttpAsyncHeadersInterceptor } from "./modules/http-async/interceptors/http-async-headers.interceptor";
import { RefreshTokenInterceptor } from "./modules/http-async/interceptors/refresh-token.interceptor";
import { RetryInterceptor } from "./modules/http-async/interceptors/retry.interceptor";
import { IsOfflineInterceptor } from "./modules/network/interceptors/is-offline.interceptor";
import { NetworkModule } from "./modules/network/network.module";
import { InstanceService } from "./services/instance/instance.service";
import { RoleService } from "./services/role/role.service";
import { getNewPaginatorIntl } from "./shared/pagination/portuguese-paginator";

registerLocaleData(ptPt, "pt-PT");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NbToastrModule.forRoot({
      duration: 5000,
      destroyByClick: true,
      limit: 3,
    }),
    NbMomentDateModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbAutocompleteModule,
    NbDialogModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbSecurityModule.forRoot({
      accessControl: {
        none: {
          view: [],
          update: [],
          create: [],
          remove: [],
        },
        teacher: {
          view: [
            "dashboard",
            "site",
            "course",
            "quizzes",
            "presences",
            "category",
          ],
          update: [
            "dashboard",
            "site",
            "course",
            "quizzes",
            "presences",
            "category",
          ],
          create: [
            "dashboard",
            "site",
            "course",
            "quizzes",
            "presences",
            "category",
          ],
          remove: [
            "dashboard",
            "site",
            "course",
            "quizzes",
            "presences",
            "category",
          ],
        },
        admin: {
          view: [
            "dashboard",
            "settings",
            "report",
            "user",
            "user-import",
            "establishment",
            "sector",
            "job-title",
            "site",
            "track",
            "recipient",
            "course",
            "quizzes",
            "presences",
            "category",
            "track-category",
            "certificates",
          ],
          create: [
            "dashboard",
            "settings",
            "report",
            "user",
            "establishment",
            "sector",
            "job-title",
            "site",
            "track",
            "recipient",
            "course",
            "quizzes",
            "presences",
            "category",
            "track-category",
            "certificates",
          ],
          remove: [
            "dashboard",
            "settings",
            "report",
            "user",
            "establishment",
            "sector",
            "job-title",
            "site",
            "track",
            "recipient",
            "course",
            "quizzes",
            "presences",
            "category",
            "track-category",
            "certificates",
          ],
          update: [
            "dashboard",
            "settings",
            "report",
            "user",
            "establishment",
            "sector",
            "job-title",
            "site",
            "track",
            "recipient",
            "course",
            "quizzes",
            "presences",
            "category",
            "track-category",
            "certificates",
          ],
          duplicate: ["course"],
        },
        master: {
          view: ["dashboard", "instance"],
          create: ["dashboard", "instance", "user"],
          remove: ["dashboard", "instance", "user"],
          update: ["dashboard", "instance", "user"],
        },
        "safety-technician": {
          view: ["certificates"],
          create: ["certificates"],
          remove: ["certificates"],
          update: ["certificates"],
        },
      },
    }),
    HttpAsyncModule.withConfig({
      baseUrl: environment.api.baseUrl,
      defaultHeaders: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      bearerTokenKey: environment.keys.token,
    }),
    NetworkModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-PT" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" },
    { provide: HTTP_INTERCEPTORS, useClass: IsOfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAsyncHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getNewPaginatorIntl() },
    { provide: NbNativeDateService, useClass: NbMomentDateService },
    { provide: NbRoleProvider, useClass: RoleService },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InstanceService],
      useFactory: (instance: InstanceService) => () => instance.initialize(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
