export const routes = {
  oauth2: {
    facensRedirectLink: `/oauth2/facens`,
    callback: "/oauth2/facens/bo/callback?code={code}",
  },
  newton: {
    newtonLogin: "/auth/newton",
  },
  reports: {
    user_engagement_limit_page:
      "/reports/users/engagement?limit={limit}&page={page}",
    userEngagement: "/reports/users/engagement",
  },
  users: {
    get_companies: "/users/available-companies",
    forgotPasswordEmail: "/users/password/forgot/email/{email}",
    forgotPasswordCode: "/users/password/verify/{passwordCode}",
    resetPassword: "/users/password/reset/{resetPasswordCode}",
    canUserUpdatePassword: "/users/password/forgot/backoffice/{email}",
  },
  questions: {
    update_answers_in_bulk:
      "/quizzes/questions/answers/:questionId/answers-bulk",
  },
};
