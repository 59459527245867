import { routes } from "./routes";

export const environment = {
  production: false,
  hmr: false,
  build: 8.8,
  keys: {
    token: "TOKEN_PROXY_KEY",
    refreshToken: "REFRESH_TOKEN_PROXY_KEY",
    user: "USER_PROXY_KEY",
    forceSubdomain: "@uni/force-subdomain",
  },
  config: {
    redirectToWhenAuthenticated: "/pages/dashboard",
    redirectToWhenUnauthenticated: "/auth/login",
    maxVideoSizeInMb: 200,
    maxContentSizeInMb: 200,
    autoRefreshInterval: 10000,
    domain: "https://plataforma-dev.facchini.unicorporativa.com/",
    florestasDomain: "https://florestas-dev.unicorporativa.com",
    regenerarDomain: "https://plataforma-dev.cursoregenerar.com.br",
    staticSite: ".static.unicorporativa-facchini.unicorporativa.com",
    facens: {
      loginUrl:
        "https://auth.facens.ligafacens.com/authorize?response_type=code&redirect_uri={redirectUri}&client_id={clientId}",
      clientId: "8aefb238-e129-4b06-aef0-0122c68e7771",
      redirectUrl: "https://facens.dev.backoffice.unicorporativa.com/oauth2",
    },
  },
  api: {
    baseUrl: "https://api-dev.facchini.unicorporativa.com",
    routes,
    auth: {
      login: "/auth/local",
      newtonLogin: "/newton/bo",
    },
    users: {
      me: "/users/me",
    },
  },
};
