//#region Imports

import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { InstanceProxy } from "../../models/proxys/instance.proxy";
import { HttpAsyncService } from "../../modules/http-async/services/http-async.service";

//#endregion

/**
 * A classe que representa o serviço que lida com as instâncias
 */
@Injectable({
  providedIn: "root",
})
export class InstanceService {
  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    protected readonly router: Router,
    protected readonly http: HttpAsyncService,
    protected readonly title: Title
  ) {}

  //#endregion

  //#region Private Properties

  /**
   * As informações da instância atual
   */
  public currentInstance?: InstanceProxy;

  //#endregion

  //#region Public Methods

  /**
   * Método que realiza a inicialização do serviço de instância
   */
  public async initialize(): Promise<void> {
    const subdomain = this.getCurrentSubdomain();

    await this.loadInstanceBySubdomain();

    (window as any).__uniGetInstance = () => this.currentInstance;
    (window as any).__uniSetCurrentInstanceBySubdomain = () =>
      this.loadInstanceBySubdomain();
    (window as any).__uniClearForcedSubdomain = () =>
      localStorage.removeItem(environment.keys.forceSubdomain);
    (window as any).__uniForceSubdomain = (domain) => {
      localStorage.setItem(environment.keys.forceSubdomain, domain);

      this.loadInstanceBySubdomain();
    };
  }

  /**
   * Método que retorna o subdomínio atual
   */
  public getCurrentSubdomain(): string {
    return "facchini";
  }

  //#endregion

  //#region Private Methods

  /**
   * Método que carrega as informações de uma instância pelo subdomínio
   *
   * @param subdomain O subdomínio
   */
  private async loadInstanceBySubdomain(): Promise<void> {
    const { error, success } = await this.http.get<InstanceProxy>(
      `/instances/facchini`
    );

    if ((error && error.status === 404) || !success)
      return void (await this.router.navigateByUrl("/instance-not-found"));

    this.currentInstance = success;

    this.setupInstance(success);
  }

  /**
   * Método que realiza as configurações iniciais da instância
   *
   * @param instance As informações da instância
   */
  private setupInstance(instance: InstanceProxy): void {
    this.title.setTitle(`Painel - UniCorp ${instance.fantasyName}`);

    const faviconIcon: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');

    if (!faviconIcon) return;

    faviconIcon.href = instance.faviconUrl;
  }

  //#endregion
}
